<template>
    <div>
      <div>
        <el-row class="bgW">
        <el-col :span="20">
          <!--检索条件-->
          <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
            
             <el-form-item label="日期">
              <el-date-picker
                v-model="search.start_time"
                type="date"
                class="w130"
                placeholder="开始日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
              <el-date-picker
                v-model="search.end_time"
                type="date"
                class="w130"
                placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
            
          </el-form>
        </el-col>
        <el-col :span="4" class="btnBox">
          <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
          <!-- <el-button type="primary" class="mb10" size="mini"  :loading="exportS" @click="exportExcel">导出数据</el-button> -->
        </el-col>
      </el-row>
  
        <!--列表-->
        <el-row>
          <el-col :span="24">
            <el-table
              :data="list"
              size="small"
              border
              v-loading="loading"
              :cell-style="$style.cellStyle"
              :header-cell-style="$style.rowClass"
            >
            <ElTableColumn label="护理师" prop="name" />
            <ElTableColumn label="线上" prop="consultation" />
            <ElTableColumn label="到院" prop="reach" />
            <ElTableColumn label="汇总" prop="sum" />
            </el-table>
            <!-- <pagination
              class="page tc mt10"
              :total="page.total"
              :page.sync="page.page"
              :limit.sync="page.limit"
              @pagination="getList"
            /> -->
          </el-col>
        </el-row>
      </div>
    </div>
  </template>
  <script>
  import { getLastDay } from '@/utils';
  import pagination from "@/components/Pagination";
  import { getYellowVService } from "@/api/statistics";
  class Page {
    total = 0;
    page = 1;
    limit = 10;
  }
  class Search {
    start_time = "";
    end_time = "";
    id="";
  }
  export default {
    name: "OrderList",
    components: {
      pagination
    },
    data() {
      return {
        search: new Search(), // 检索条件
        page: new Page(), // 分页
        list: [], // 数据展示
        loading:false,
        exportS: false,
        id:"",
      };
    },
    mounted() {
      let year = new Date().getFullYear()
      let month = new Date().getMonth() + 1
      if(month.toString().length == 1){
        month = '0'+ month
      }
      let day = getLastDay(year,month)
      if(day.toString().length == 1){
        day = '0'+ day
      }
      this.search.start_time = this.$route.query.start_time;
      this.search.end_time = this.$route.query.end_time;
      this.search.id = this.$route.query.id;
      this.getList('search');
      
    },
    methods: {
      // 获取列表
      getList(option) {
        this.loading = true
        console.log(this.search);
        if (option === "search") this.page = new Page();
        getYellowVService({ ...this.search}).then(res => {
          console.log(res);
          this.list = res.data;
          this.loading = false;
        });
      },
      // 导出
      // exportExcel() {
      //   this.exportS = true
      //   getYellowV({...this.search,export:1}).then(res => {
      //     window.open(res.data.url);
      //     this.exportS = false
      //   });
      // },
      // 跳转事件
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .hand{
    cursor:pointer;
  }
  .btnBox{
    text-align: right;
  }
  </style>
  